import React, { useState, useRef, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { ThemeContext } from "../../contexts/ThemeContext";
import { UserContext } from '../../contexts/UserContext';

const DeskTopSearchBar = () => {
  const [searchValue, setSearchValue] = useState(''); // State to manage the current input value
  const inputRef = useRef(); // Reference to the input field
  const router = useRouter(); // Next.js router for navigation
  const theme = useContext(ThemeContext); // Accessing the theme context
  const { featureCustomizations, metalCategories } = useContext(UserContext); // Accessing user-related data from context
  const [searchKey, setSearchKey] = useState([]); // State to store search keys (placeholder values)
  const [currentProductIndex, setCurrentProductIndex] = useState(0); // State to track the current placeholder index

  // Handle the search functionality
  const handleSearch = (e) => {
    e.preventDefault();
    const searchUrl = theme.siteStatus === 0 ? `/search/${searchValue}?pid=${theme.token}` : `/search/${searchValue}`;
    router.push(searchUrl);
    setSearchValue(''); // Clear the input field
    inputRef.current.blur(); // Remove focus from the input field
  };

  // Trigger search on Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  // Populate searchKey based on featureCustomizations or metalCategories
  useEffect(() => {
    if (featureCustomizations && featureCustomizations.is_search_key_enabled == 1 && featureCustomizations.search_key) {
      setSearchKey(featureCustomizations.search_key);
    } else {
      getCategoryNames(metalCategories);
    }
  }, [featureCustomizations, metalCategories]);

  // Extract names from metalCategories to populate searchKey
  const getCategoryNames = (names) => {
    var totalItems = [];
    if (!Array.isArray(names)) {
      return;
    }
    names.forEach(item => {
      totalItems.push(item.name);
      if (item.child) {
        item.child.forEach(subItem => {
          if (subItem.child) {
            subItem.child.forEach(innerItem => {
              totalItems.push(innerItem.name);
            });
          }
        });
      }
    });
    setSearchKey(totalItems);
  };

  // Randomly cycle through searchKey items for placeholder
  const cycleProducts = () => {
    setCurrentProductIndex(Math.floor(Math.random() * searchKey.length));
  };

  // Set an interval to cycle through products every 3 seconds
  useEffect(() => {
    const interval = setInterval(cycleProducts, 3000);
    return () => clearInterval(interval);
  }, [searchKey]);

  return (
    <div className="w-full md:flex justify-center items-center md:w-[40%] lg:w-[40%] hidden my-4">
      <input
        type="text"
        className="pl-4 w-full border border-r-0 border-primary py-3 h-14 min-h-[56px] max-w-full" // Prevent layout shift
        placeholder={
          searchKey[currentProductIndex]
            ? `Search for ${searchKey[currentProductIndex]}`
            : 'Search'
        }
        value={searchValue} // Bind the input value to state
        onChange={(e) => setSearchValue(e.target.value)} // Update state on input change
        onKeyPress={handleKeyPress} // Handle key press events
        ref={inputRef} // Attach the ref to the input field
        style={{ width: '100%', maxWidth: 'none', minHeight: '56px', height: '56px', padding: '0.75rem', transition: 'none' }} // Ensure width and height consistency
      />
      <button
        type="submit"
        className="bg-primary border border-primary text-white px-6 font-medium h-14"
        onClick={handleSearch} // Trigger search on button click
        style={{ height: '56px', transition: 'none' }} // Ensure button height consistency
      >
        <i className="fas fa-search"></i>
      </button>
    </div>
  );
};

export default DeskTopSearchBar;
