'use client';

import axios from 'axios';
import React, { useState, useEffect } from "react";
import '../styles/globals.css';
import 'swiper/swiper.min.css';
import Head from "next/head";
import Layout from '../components/Layouts/Layout';
import { CurrencyProvider } from '../contexts/CurrencyContext';
import { JboProvider } from '../contexts/ContextJbo';
import { ThemeProvider } from '../contexts/ThemeContext';
import { CartProvider } from '../contexts/CartContext';
import { WishlistProvider } from '../contexts/WishlistContext';
import { UserProvider } from '../contexts/UserContext';
import { useRouter } from 'next/router';
import ErrorBoundary from '../components/ErrorBoundary';
import Empty from './Empty';
import { setCookie, getCookie } from '../utils/cookie';
import '/public/fontawesome/css/all.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
function transformLiveData(inputData) {
  return {
    primary: inputData.primary_colour,
    secondary: inputData.secondary_colour,
    alternative: inputData.alternative_colour,
    font: inputData.primary_font,
    secondaryFont: inputData.secondary_font,
    alternativeFont: inputData.alternative_font,
    title: "Default Title",
    content: "Default Content",
    currency: "Default Currency",
    token: inputData.token,
    siteStatus: inputData.site_status,
    splashLogo: inputData.spalsh_logo,
    favicon: inputData.fav_icon,
  };
}

function transformData(inputData) {
  return {
    primary: inputData.primary_color,
    secondary: inputData.secondary_color,
    alternative: inputData.alternative_color,
    font: inputData.primary_font,
    secondaryFont: inputData.secondary_font,
    alternativeFont: inputData.alternative_font,
    title: "Default Title",
    content: "Default Content",
    currency: "Default Currency",
    token: inputData.token,
    siteStatus: inputData.site_status,
    splashLogo: inputData.spalsh_logo,
    favicon: inputData.fav_icon,
  };
}

function processPropsData(products) {
  if (products) {
    let previewData = {};
    let liveData = {};
    const hostname = window.location.hostname === "localhost" ? process.env.NEXT_PUBLIC_WEB_HOST : window.location.hostname;
    let _transformedData = null;
    let _jboId = null;

    if (!hostname.startsWith('preview')) {
      if (Array.isArray(products)) {
        products.forEach(item => {
          if (item.site_status === 0) {
            previewData = item;
          } else if (item.site_status === 1) {
            liveData = item;
          }
        });
        _jboId = JSON.stringify(liveData.jbo_id);
        setCookie('jb_id', _jboId, 7);
        _transformedData = JSON.stringify(transformLiveData(liveData));
        setCookie('productsData', _transformedData, 7);
      } else {
        _jboId = JSON.stringify(products.jbo_id);
        setCookie('jb_id', _jboId, 7);
        _transformedData = JSON.stringify(transformData(products));
        setCookie('productsData', _transformedData, 7);
      }
    } else {
      _jboId = JSON.stringify(products.jbo_id);
      setCookie('jb_id', _jboId, 7);
      _transformedData = JSON.stringify(transformData(products));
      setCookie('productsData', _transformedData, 7);
    }
    return { productsData: _transformedData, jboId: _jboId };
  } else {
    return null;
  }
}

function MyApp({ Component, pageProps }) {
 
  const router = useRouter();
  const [localProductsData, setLocalProductsData] = useState(null);
  const [jboIdData, setJboIdData] = useState();
  const [showChild, setShowChild] = useState(false);
  const [favicon, setFavicon] = useState('');
  const [trackingId, setTrackingId] = useState(null);
  const [tagManagerId, setTagManagerId] = useState(null);
  const [clarityClientId, setClarityClientId] = useState(null);
  const [googleVerificationId, setGoogleVerificationId] = useState(null);
  // state for google auth id
  const [GClientId, setGoogleClientId] = useState("");
  const [carryEggScript, setCarryEggScript] = useState(null); // Store the Crazy Egg script
  const [mixPanelToken, setMixPanelToken] = useState(null);
  const [hotJarScript, setHotJarScript] = useState(null);
  const [zopimChat, setZopimChat] = useState(null);
   
  useEffect(() => {
    let productsData = null;
    let jboId = null;

    if (pageProps.products) {
      const out = processPropsData(pageProps.products);
      productsData = out.productsData;
      jboId = out.jboId;
    }

    const storedProductsData = getCookie('productsData') ?? productsData;
    setShowChild(true);
    const storedJboId = getCookie('jb_id') ?? jboId;

    if (storedProductsData) {
      const parsedData = JSON.parse(storedProductsData);
      const parsedJboId = JSON.parse(storedJboId);
      setJboIdData(parsedJboId);
      setLocalProductsData(parsedData);
      setFavicon(parsedData.favicon);
    }

    if (typeof window !== 'undefined') {
      const url = window.location.href;
      const parsedUrl = new URL(url);
      const currentHostname = window.location.hostname === "localhost" ? process.env.NEXT_PUBLIC_WEB_HOST : parsedUrl.hostname;

      const payload = {
        data: JSON.stringify({ jbo_id: storedJboId }),
      };

      axios.post(`https://${currentHostname}/api/settings/viewGoogleIntegration`, payload)
        .then(response => {
          const { google_analytics, google_tag_manager, google_verification,microsoft_clarity } = response.data;

          if (google_analytics && google_analytics.length > 0) {
            setTrackingId(google_analytics[0].analytics_tracking_id);
          }

          if (google_tag_manager && google_tag_manager.length > 0 && google_tag_manager[0].status === 1) {
            setTagManagerId(google_tag_manager[0].google_tag_manager_id);
          }

          if (google_verification && google_verification.length > 0 && google_verification[0].status === 1) {
            setGoogleVerificationId(google_verification[0].google_verification_id);
          }

          if (microsoft_clarity && microsoft_clarity.length > 0 && microsoft_clarity[0].status === 1) {
            setClarityClientId(microsoft_clarity[0].client_id);
          }
        })
        .catch(error => {
          console.error('Error fetching Google Integration data:', error);
        });

      
       // Fetch Carry Egg script
    axios.post(`https://${currentHostname}/api/settings/viewCarryEgg`, payload)
    .then(response => {
      if (response.data && response.data.status == 1) {
        setCarryEggScript(response.data.data.carryegg_script); // Dynamically load carry egg script
      }
    })
    .catch(error => {
      console.error("Error fetching carry egg script:", error);
    });
    axios.post(`https://${currentHostname}/api/settings/viewMixPanel`, payload)
    .then(response => {
      if (response.data && response.data.status === 1) {
        const { token, status } = response.data.data;
        if (status === 1) {
          setMixPanelToken(token); // Set the MixPanel token if enabled
        }
      }
    })
    .catch(error => {
      console.error('Error fetching MixPanel data:', error);
    });
  // Fetch HotJar script details
  axios.post(`https://${currentHostname}/api/settings/viewHotJar`, payload)
  .then(response => {
    if (response.data && response.data.status === 1 && response.data.data.status === 1) {
      const { hotjar_script } = response.data.data;
      setHotJarScript(hotjar_script); // Store the HotJar script ID
    }
  })
  .catch(error => {
    console.error('Error fetching HotJar script:', error);
  });

     // Fetch Zopim chat details
     axios.post(`https://${currentHostname}/api/settings/viewZopim`, payload)
     .then(response => {
       if (response.data && response.data.status === 1 && response.data.data.status === 1) {
         setZopimChat(response.data.data.zopim_chat); // Store Zopim chat ID
       }
     })
     .catch(error => {
       console.error('Error fetching Zopim chat script:', error);
     });

    }
  }, []);
 
  console.log("googleClientId",GClientId);
  useEffect(() => {
    if (clarityClientId) {
      const clarityScript = document.createElement('script');
      clarityScript.type = 'text/javascript';
      clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${clarityClientId}");
      `;
      document.head.appendChild(clarityScript);
    }
  }, [clarityClientId]);

  useEffect(() => {
    if (tagManagerId) {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${tagManagerId}');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    }
  }, [tagManagerId]);

  
   // Dynamically inject the MixPanel script
   useEffect(() => {
    if (mixPanelToken) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.innerHTML = `
        (function(e,a){ 
          if(!a.__SV){
            var b=window;
            try{ 
              var c,l,i,j=b.location,g=j.hash;
              c=function(a,b){ return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null}; 
              g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))), 
              "mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g), history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))
            }catch(m){}
            var k,h;
            window.mixpanel=a;
            a._i=[];
            a.init=function(b,c,f){ 
              function e(b,a){
                var c=a.split(".");
                2==c.length&&(b=b[c[0]], a=c[1]);
                b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,0)))}
              }
              var d=a;
              "undefined"!==typeof f?d=a[f]=[]:f="mixpanel";
              d.people=d.people||[];
              d.toString=function(b){
                var a="mixpanel";
                "mixpanel"!==f&&(a+="."+f);
                b||(a+=" (stub)");
                return a
              };
              d.people.toString=function(){return d.toString(1)+" (people stub)"};
              k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
              for(h=0;h<k.length;h++)e(d,k[h]);
              a._i.push([b,c,f])
            };
            a.__SV=1.2;
            b=e.createElement("script");
            b.type="text/javascript";
            b.async=!0;
            b.src="https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";
            c=e.getElementsByTagName("script")[0];
            c.parentNode.insertBefore(b,c)
          }
        })(document,window.mixpanel||[]);
        mixpanel.init("${mixPanelToken}");
      `;
      document.head.appendChild(scriptElement);
    }
  }, [mixPanelToken]);
  // Inject Crazy Egg script dynamically
  useEffect(() => {
    if (carryEggScript) {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.async = true;
      scriptElement.src = `https://script.crazyegg.com/pages/scripts/${carryEggScript}.js`; // Use the fetched script dynamically
      document.head.appendChild(scriptElement);
    }
  }, [carryEggScript]);
 // Inject HotJar script dynamically
 useEffect(() => {
  if (hotJarScript && typeof window !== 'undefined') {
    // Ensure hotJarScript is a valid number before injecting the script
    if (!isNaN(hotJarScript)) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.innerHTML = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hotJarScript}, hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(scriptElement);
    } else {
      console.error('Invalid HotJar ID:', hotJarScript);
    }
  }
}, [hotJarScript]);
// Inject Zopim/Zendesk Chat script dynamically
useEffect(() => {
  if (zopimChat && typeof window !== 'undefined') {
    const zopimScript = document.createElement('script');
    zopimScript.type = 'text/javascript';
    zopimScript.async = true;
    zopimScript.innerHTML = `
      window.$zopim||(function(d,s){
        var z=$zopim=function(c){z._.push(c)},$=z.s=
        d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
        _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
        $.src="https://v2.zopim.com/?${zopimChat}";z.t=+new Date;$.
        type="text/javascript";e.parentNode.insertBefore($,e)
      })(document,"script");
    `;
    document.head.appendChild(zopimScript); // Append Zopim chat script to head
  }
}, [zopimChat]);
  // Check if pageProps.products is an array or an object and extract the Google Client ID accordingly
  useEffect(() => {
    if (pageProps?.products) {
      if (Array.isArray(pageProps.products)) {
        // If products is an array, prioritize site_status === 1
        const liveProduct = pageProps.products.find(product => product.site_status === 1);
        
        if (liveProduct) {
          setGoogleClientId(liveProduct.client_id);
        }
      } else {
        // If products is an object, directly use its client_id
        setGoogleClientId(pageProps.products.client_id);
      }
    }
  }, [pageProps.products]);
  const excludedRoutes = ['/auth/SignIn', '/auth/Register', '/auth/ForgotPassword', 'user/forgotpsd'];
  const isExcludedRoute = excludedRoutes.some(route => router.pathname.startsWith(route));
  const isSitemap = router.pathname === '/sitemap.xml';

  if (!showChild) {
    return null;
  }

  let content;
  if (isSitemap) {
    content =  <>
   
      <Component {...pageProps} />
  
  </>;
  } else if (isExcludedRoute) {
    content =   <>
  
      <Component {...pageProps} />

  </>;
  } else {
    content = (
      <>
        {!jboIdData && <Empty />}
        <Layout>
          <ErrorBoundary>
          <>
      
          <Component {...pageProps} />
        
      </>
          </ErrorBoundary>
        </Layout>
      </>
    );
  }

  let primary_font = "Poppins";
  let secondary_font = "Roboto";

  if (pageProps.products) {
    primary_font = pageProps?.products[0]?.primary_font ? pageProps?.products[0]?.primary_font : pageProps?.products?.primary_font ?? 'Poppins';
    secondary_font = pageProps?.products[0]?.secondary_font ? pageProps?.products[0]?.secondary_font : pageProps?.products.secondary_font ?? 'Roboto';
  }

  const encodedPrimaryFont = encodeURIComponent(primary_font);
  const encodedSecondaryFont = encodeURIComponent(secondary_font);

  const fontFamilies = [
    "Poppins:wght@400;600",
    "Roboto:wght@400;700",
  ];

  const primaryFontUrl = fontFamilies.find(family => family.includes(encodedPrimaryFont)) || encodedPrimaryFont;
  const secondaryFontUrl = fontFamilies.find(family => family.includes(encodedSecondaryFont)) || encodedSecondaryFont;

  const googleFontsUrlP = `https://fonts.googleapis.com/css2?family=${primaryFontUrl}&family=${secondaryFontUrl}&display=swap`;

  return (
    <div>
      <Head>
        {/* DNS Prefetching */}
        <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />

        {/* Preconnect to external resources */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="preconnect" href="https://www.googletagmanager.com" />

        {/* Preload Google Fonts */}
        <link rel="preload" href={googleFontsUrlP} as="style" />
        <link href={googleFontsUrlP} rel="stylesheet" />

        

        {/* Preload Swiper CSS */}
        <link rel="preload" href="/_next/static/css/swiper.min.css" as="style" />
        <link rel="stylesheet" href="/_next/static/css/swiper.min.css" />

        {/* Preload Global Styles */}
        <link rel="preload" href="/_next/static/css/globals.css" as="style" />
        <link rel="stylesheet" href="/_next/static/css/globals.css" />

        {/* Preload and Prefetch Scripts */}
        {trackingId && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`} />
            <script dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${trackingId}');
              `,
            }} />
          </>
        )}
        {router.pathname === '/' && googleVerificationId && (
          <meta name="google-site-verification" content={googleVerificationId} />
        )}
        <link rel="icon" href={favicon} />
      </Head>

      <ThemeProvider theme={localProductsData}>
        <CurrencyProvider>
          <JboProvider initialJboId={jboIdData}>
            <UserProvider>
              <CartProvider>
                <WishlistProvider>
                  <GoogleOAuthProvider clientId={GClientId}>
                  {content}
                  </GoogleOAuthProvider>
                </WishlistProvider>
              </CartProvider>
            </UserProvider>
          </JboProvider>
        </CurrencyProvider>
      </ThemeProvider>
    </div>
  );
}

export default MyApp;
