import { useMediaQuery } from '@react-hook/media-query';

export function useDeviceSize() {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(max-width: 1023px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');

  return {
    isMobile,
    isTablet,
    isLaptopL
  };
}