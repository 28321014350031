import React from 'react';
import PhoneIcon from './PhoneIcon';
import ChatIcon from './ChatIcon';

const ContactUs = () => {
    return (
        <div>
            {/* Chat Icon */}
            <ChatIcon />
            {/* Phone Number Icon */}
            <PhoneIcon />
        </div>
    );
};

export default ContactUs;
