import React from 'react';
import * as Sentry from '@sentry/nextjs';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  

  static getDerivedStateFromError(error) {
    console.error('error 1', error.message);
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service
    Sentry.captureException(error, { extra: errorInfo });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can customize the fallback UI here
      return (
        <div className="flex flex-col items-center justify-center h-screen">
        <img src="/images/404/sww.jpg" alt="Error" className="w-60 h-60 mb-8" />
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Oops!</h2>
          <p className="text-lg text-gray-800">{this.state.hasError}</p>
          <p className="text-lg text-gray-800">An unexpected error occurred. We apologize for the inconvenience. Please try again later.</p>
        </div>
      </div>
      
      );
    }
  
    return this.props.children;
  }
}

export default ErrorBoundary;