import axios from 'axios';
import { useJobId } from '../contexts/ContextJbo';
import { useContext } from "react";
import { CurrencyContext } from '../contexts/CurrencyContext';
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { BASE_URL } from './config';
import * as Sentry from "@sentry/react";

/**
 * Custom hook to create an Axios instance with specific configurations and interceptors.
 * It leverages contexts for job ID and currency to enrich each request with additional data.
 *
 * @returns {AxiosInstance} - The configured Axios instance.
 */
export function useAxios() {
  const { jboId } = useJobId();
  const theme = useContext(ThemeContext);
  const { userId, token } = useContext(UserContext);
  const { currentCurrency } = useContext(CurrencyContext);

  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 30000, // 30 seconds timeout
  });

  // Interceptor to modify every outgoing request.
  instance.interceptors.request.use((config) => {

    console.log("config : ", config);
    

    // Adding job ID and currency information to the request's payload.
    let dataPayload = {
      ...config.data,
      client_id: jboId,
      jbo_id: jboId,
      user_id: userId,
      currency: currentCurrency,
    };

    // dataPayload = JSON.parse(dataPayload)

    // console.log("payload : ",  config.data);
    // console.log("payload type: ", config.data);
    

    // Directly stringify the payload and set it to config.data
    config.data = {
      data: JSON.stringify(dataPayload) // No extra wrapping, stringified directly
      // data: typeof dataPayload == 'string' ? dataPayload : JSON.stringify(dataPayload) // No extra wrapping, stringified directly
    };

    // Store the original data to use for retries
    config.originalData = config.data;

    console.log("configData:", config.data);
    return config;
  }, (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  });

  // Retry logic
  const retryRequest = async (error) => {
    
    console.log("err conf:", error.config);
    

    const config = error.config;

    // Check if the request has a config and retry is allowed
    if (!config || config.__retryCount >= 3) {
      return Promise.reject(error);
    }

    // Set up retry count if it doesn't exist
    config.__retryCount = config.__retryCount || 0;

    // Check if the error is a timeout or a cancellation
    if (error.code === 'ECONNABORTED' || error.message.includes('timeout') || error.message.includes('canceled')) {
      config.__retryCount += 1; // Increment retry count
      console.log(`Retrying request (${config.__retryCount}/3)...`);

      // Wait for 1 second before retrying
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Use the original data for the retry request
      config.data = JSON.parse(config.originalData.data);

      // Retry the request
      return instance.request(config);
    }

    return Promise.reject(error);
  };

  // Interceptor to handle response errors and retry logic
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.error('API request failed:', error);
      Sentry.captureException(error);

      // Retry the request up to 3 times if it times out or is cancelled
      return retryRequest(error);
    }
  );

  return instance;
}
